<template>
  <!-- ROW 1 -->
  <div class="contained-example-container">
    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <statistics-card-line
        hideChart
        class="mb-base"
        :icon="icon"
        :statistic="count"
        :statisticTitle="title"
      />
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import { mapState, mapActions } from 'vuex'
const moment = require('moment')

export default {
  name: 'CardsV2',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    versions: {
      type: Array,
      required: true
    },
    interval: Object
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      count: 0,
      icon: ''
    }
  },
  components: {
    StatisticsCardLine
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    async load(report) {
      this.icon = this.report._extra.icon
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        platforms: this.platforms,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        this.count = result.data[0][0].count
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>
